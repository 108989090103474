<template>
    <div class="form-elements">
        <vuestic-widget :headerText="$t('view.product.title')">
            <div class="row">
                <div class="col-md-3">
                    <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.product.back' | translate}}</button>
                </div>
                <!--<div class="col-md-3 offset-md-6">-->
                    <!--<button type="button" v-on:click="trashUser" class="btn btn-warning btn-sm">Delete Element</button>-->
                    <!--</div>-->

                </div>

            </vuestic-widget>
            <div class="row">
                <div class="col-md-12">
                    <vuestic-widget :headerText="'view.product.new' | translate">
                        <form @submit.prevent="editSubmit" autocomplete="off">
                            <div class="row">
                                <div class="col-md-12">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-md-4 with-icon-right">
                                                <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                                                    <div class="input-group">
                                                        <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                        <label class="control-label" for="name">{{'view.product.fields.name' | translate}} </label><i class="bar"></i>
                                                        <small v-show="errors.has('name')" class="help text-danger">
                                                            {{ errors.first('name') }}
                                                        </small>
                                                    </div>
                                                </div>
                                                  <div class="form-group with-icon-right" :class="{'has-error': errors.has('type_product')}">
                                                    <div class="input-group">
                                                       <multiselect
                                                       v-model="id_type_product"
                                                       deselect-label="Selected Element"
                                                       label="name"
                                                       placeholder="Select an Element"
                                                       :options="type_product_array"
                                                       :searchable="false"
                                                       :allow-empty="false"
                                                       >
                                                       <template slot="singleLabel" slot-scope="{ option }">
                                                        {{'view.product.fields.type_product' | translate}}:
                                                        <label style="font-weight:600">{{ option.name }}</label>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                            </div>
                                            <div class="col-md-4 with-icon-right">
                                                <div class="form-group with-icon-right" :class="{'has-error': errors.has('description'), 'valid':isSuccessDescriptionValid}">
                                                    <div class="input-group">
                                                        <input id="description" name="description" v-model="description" v-validate="'required'" />
                                                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                        <label class="control-label" for="description">{{'view.product.fields.description' | translate}} </label><i class="bar"></i>
                                                        <small v-show="errors.has('description')" class="help text-danger">
                                                            {{ errors.first('description') }}
                                                        </small>
                                                    </div>
                                                </div>
                                                  <div class="form-group with-icon-right" :class="{'has-error': errors.has('description'), 'valid':isSuccessDescriptionValid}">
                                            
                                              <multiselect
                                                v-model="promotion"
                                                deselect-label="Elemento Seleccionado"
                                                label="name"
                                                placeholder="Promoción"
                                                :options="promotionoptions"
                                                :searchable="false"
                                                :allow-empty="false"
                                                >
                                                <template slot="singleLabel" slot-scope="{ option }">
                                                    Promoción:
                                                    <label style="font-weight:600">{{ option.name }}</label>
                                                </template>
                                                </multiselect>
                                             </div>
                                            </div>
                                            <div class="col-md-4 with-icon-right">
                                                <div class="form-group with-icon-right" :class="{'has-error': errors.has('description'), 'valid':isSuccessDescriptionValid}">
                                                    <div class="input-group">
                                                        <vuestic-switch v-model="active">
                                                            <span slot="trueTitle">{{$t('view.product.activated') | translate}}</span>
                                                            <span slot="falseTitle">{{$t('view.product.disabled') | translate}}</span>
                                                        </vuestic-switch>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 with-icon-right">
                                              
                                    </div>

                                </div>
                            </fieldset>
                            <div class="col-md-3 offset-md-12">
                                <button class="btn btn-primary btn-sm" type="submit">Actualizar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </vuestic-widget>
        </div>
    </div>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UpdateProduct',
  components: {
    Multiselect
  },
  data () {
    return {
      name: '',
      description: '',
      id_type_product: '',
      idpromo:'',
        promotion:"",
      promotionoptions: [{name:'Normal',id:1},{name:'Gratuito',id:2},{name:'2x1',id:3}],
      type_product_array: [],
      active: 1,
    }
  },
  mounted () {
    var id = this.$route.params.id
    if (id !== null) {
      this.loadProduct(id)
        .then((data) => {
          this.name = data.name;
          this.description = data.description;
          this.id_type_product = data.type_product;
          this.idpromo= this.id_promotion;
          this.active = data.active;
        })
        .catch(data => {
          this.addToastMessage({
            text: 'Ocurrio un error',
            type: 'success'
          })
        })
    } else {
      this.addToastMessage({
        text: 'Ocurrio un error',
        type: 'success'
      })
    }
    this.loadTypeProductsActive()
      .then((data) => {
        this.type_product_array = data
      })
      .catch(data => {
        this.error = data.message
      })
  },
  methods: {
    ...mapActions([
      'loadTypeProductsActive',
      'loadProduct',
      'updateProduct', 
      'addToastMessage'
    ]),
    back () {
      this.$router.replace('/products')
      // this.$router.go(-1)
    },
    editSubmit () {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id
          this.updateProduct({ id: id, name: this.name, 
          description: this.description, 
          id_type_product: this.id_type_product.id,
          id_promotion:this.promotion.id,
           active: this.active })
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() => this.$router.go(-1), 2000)
            })
            .catch(data => {
              this.error = data.message
              this.errors = data.errors || {}
            })
        }
      })
    },
    cleanform () {
      this.name = null
      this.email = null
    }

  },
  computed: {
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessDescriptionValid () {
      let isValid = false
      if (this.formFields.description) {
        isValid = this.formFields.description.validated && this.formFields.description.valid
      }
      return isValid
    },
  },
}
</script>
